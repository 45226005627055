import React, { useEffect, useState } from 'react';
import { useI18n } from 'services/i18n/hook';
import { Card, Row, Col } from 'react-bootstrap';
import WorkflowSelector from 'components/workflow/selector';
import { useWorkflow } from 'services/workflow/hook';
import SearchInText from './search_in_text';
import GenieProvider from 'services/genie/provider';

const WorkflowGenie = () => {
  const { selectedWorkflow } = useWorkflow();
  const { t } = useI18n();

  return (
    <>
      <Card>
        <Card.Header className="bg-light">
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <div>
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                  {selectedWorkflow
                    ? selectedWorkflow.workflow.subject_name
                    : t(
                        'components.workflow.genie.container.select_a_workflow',
                      )}
                </h5>
              </div>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
              <WorkflowSelector />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          {selectedWorkflow && (
            <GenieProvider>
              <SearchInText />
            </GenieProvider>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default WorkflowGenie;
