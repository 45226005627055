import React, { useContext, useState } from 'react';
import { Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import GenieContext from 'services/genie/context';
import TextContent from './text_content';
import Result from './result';
import { useWorkflow } from 'services/workflow/hook';
import OCR from './ocr';
const SearchInText = () => {
  const { askGenie, askingGenie, ocr } = useContext(GenieContext);
  const [textValue, setTextValue] = useState('');
  const [result, setResult] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const { selectedWorkflow, selectedEnv } = useWorkflow();
  const [ocrResult, setOcrResult] = useState(null);
  const [ocrFileUrl, setOcrFileUrl] = useState(null);
  const handleAskGenie = async () => {
    const result = await askGenie({
      file: selectedFile,
      text: textValue,
      workflowId: selectedWorkflow.workflow.id,
      env: selectedEnv,
    });
    setResult(result);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleOcr = async () => {
    console.log('[OCR] -> selectedFile', selectedFile);
    const result = await ocr({
      file: selectedFile,
      workflowId: selectedWorkflow.workflow.id,
      env: selectedEnv,
    });
    setOcrResult(result.html);
    setOcrFileUrl(result.fileUrl);
  };

  const handleOcrAndGenie = async () => {
    const result = await askGenie({
      file: selectedFile,
      useOcr: true,
      workflowId: selectedWorkflow.workflow.id,
      env: selectedEnv,
    });
    setResult(result);
  };

  return (
    <div className="p-2">
      <input
        type="file"
        onChange={handleFileChange}
        className="mb-3"
        accept=".pdf,.jpg,.jpeg,.png,.gif,.bmp,.tiff,.webp"
      />
      <TextContent value={textValue} onChange={setTextValue} />
      <ButtonGroup className="mt-2">
        <Button onClick={handleAskGenie} disabled={askingGenie}>
          Ask Genie
        </Button>
        <Button onClick={handleOcr} disabled={askingGenie}>
          OCR
        </Button>
        <Button onClick={handleOcrAndGenie} disabled={askingGenie}>
          OCR + Genie
        </Button>
      </ButtonGroup>
      {askingGenie ? (
        <div>Loading...</div>
      ) : (
        <>
          {ocrResult ? (
            <div
              className="mt-2"
              style={{
                width: '1000px',
                height: '800px',
                border: '1px solid #ccc',
                overflowY: 'scroll',
              }}
            >
              <OCR content={ocrResult} fileUrl={ocrFileUrl} />
            </div>
          ) : (
            <div className="mt-2">
              <Result result={result} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SearchInText;
