import React from 'react';
import { useWatson } from '../../services/watson/hook';
import { useApplication } from 'services/application/hook';
import { useOffice } from 'services/office/provider';

const WatsonPreview = () => {
  const { addinContext } = useApplication();
  const { previewURL, runWatson } = useWatson();
  const { getDocumentAsFile } = useOffice();

  const getFile = async () => {
    try {
      const file = await getDocumentAsFile();
      await runWatson(file, true);
    } catch (e) {
      console.log('error', e);
    }
  };

  if (!previewURL) {
    if (addinContext) {
      return (
        <div>
          <button onClick={getFile}>Get preview</button>
        </div>
      );
    }
    return null;
  }
  return (
    <div>
      <iframe
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${previewURL}`}
        width="100%"
        height="700px"
      >
        This is an embedded{' '}
        <a target="_blank" href="http://office.com">
          Microsoft Office
        </a>{' '}
        document, powered by{' '}
        <a target="_blank" href="http://office.com/webapps">
          Office Online
        </a>
        .
      </iframe>
    </div>
  );
};

export default WatsonPreview;
