import React, { useState } from 'react';
import JsonViewer from 'components/ui/json_viewer';
import { Button } from 'react-bootstrap';
import { useWorkflow } from 'services/workflow/hook';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { PAUL_DOMAINS, IG_DOMAINS } from 'constants';

const Result = ({ result }) => {
  const [creatingRequest, setCreatingRequest] = useState(false);
  const { selectedWorkflow, loading, selectedEnv } = useWorkflow();
  const [request, setRequest] = useState(null);
  console.log('workflow', selectedWorkflow);
  console.log('env', selectedEnv);
  const createRequest = () => {
    setCreatingRequest(true);
    const domain = PAUL_DOMAINS[selectedEnv].path;
    axios
      .post(
        `${domain}api/requests`,
        {
          request: {
            user_id: 'usr_sta_6n0yBvZ10GhC',
            product_id: selectedWorkflow.product.id,
            data: {
              ...result?.data,
            },
          },
        },
        {
          headers: {
            'x-version': '1.0.0',
            Strategy: 'public_api',
            Token: 'dMyiFfiC5gTfktseQfgYxM6r',
          },
        },
      )
      .then((res) => {
        console.log('res', res.data);
        setRequest(res.data.request);
      })
      .finally(() => {
        setCreatingRequest(false);
      });
  };

  if (typeof result === 'string') {
    result = JSON.parse(result);
  }

  const data = result?.data;

  return (
    <>
      <div className="mb-2">
        <JsonViewer json={data} />
      </div>
      {!isEmpty(data) && (
        <Button
          disabled={creatingRequest}
          onClick={() => {
            createRequest();
          }}
        >
          Create request
        </Button>
      )}
      {request && (
        <div>
          <a
            target="_blank"
            href={`https://${request.product.brand.slug}.${IG_DOMAINS[selectedEnv]}/requests/${request.id}`}
          >
            {request.name}
          </a>
        </div>
      )}
    </>
  );
};

export default Result;
