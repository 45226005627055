import React from 'react';

const TextContent = ({ value, onChange }) => {
  return (
    <div>
      <textarea
        className="form-control"
        rows={10}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default TextContent;
