import React from 'react';
import './ocr.css';

const OCR = ({ content, fileUrl }) => {
  // Parse le contenu HOCR brut
  const parseHocrContent = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Récupère toutes les lignes
    const lines = doc.querySelectorAll('.ocr_line');

    return Array.from(lines).map((line) => {
      // Extrait les informations de bbox du titre de la ligne
      const lineTitle = line.getAttribute('title') || '';
      const lineBbox = lineTitle.match(
        /bbox\s+(\d+\.?\d*)\s+(\d+\.?\d*)\s+(\d+\.?\d*)\s+(\d+\.?\d*)/,
      );

      // Récupère tous les mots de la ligne
      const words = Array.from(line.querySelectorAll('.ocrx_word')).map(
        (word) => {
          const wordTitle = word.getAttribute('title') || '';
          const wordBbox = wordTitle.match(
            /bbox\s+(\d+\.?\d*)\s+(\d+\.?\d*)\s+(\d+\.?\d*)\s+(\d+\.?\d*)/,
          );

          // Extrait le style de police du style inline ou de l'attribut x_font
          const fontFamily =
            word.getAttribute('x_font') ||
            word.style.fontFamily ||
            word.getAttribute('style')?.match(/font-family:([^;]+)/)?.[1];

          return {
            text: word.textContent,
            bbox: wordBbox ? wordBbox.slice(1).map(Number) : null,
            confidence: word.getAttribute('x_wconf'),
            font: fontFamily,
          };
        },
      );

      return {
        bbox: lineBbox ? lineBbox.slice(1).map(Number) : null,
        words,
      };
    });
  };

  // Rendu d'une ligne
  const OCRLine = ({ bbox, words }) => {
    if (!bbox) return null;

    const style = {
      position: 'absolute',
      left: `${bbox[0]}px`,
      top: `${bbox[1]}px`,
      width: `${bbox[2] - bbox[0]}px`,
      height: `${bbox[3] - bbox[1]}px`,
    };

    return (
      <div className="ocr_line" style={style}>
        {words.map((word, index) => (
          <span
            key={index}
            className="ocrx_word"
            style={{
              position: 'absolute',
              left: `${word.bbox ? word.bbox[0] - bbox[0] : 0}px`,
              top: '0',
              fontFamily: word.font,
              opacity: word.confidence ? Number(word.confidence) / 100 : 1,
            }}
          >
            {word.text}
          </span>
        ))}
      </div>
    );
  };

  // Traitement du contenu

  const lines = typeof content === 'string' ? parseHocrContent(content) : [];
  console.log('[OCR] fileUrl', fileUrl);
  return (
    <div className="ocr-container">
      {lines.map((line, index) => (
        <OCRLine key={index} {...line} />
      ))}
    </div>
  );
};

export default OCR;
