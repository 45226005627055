import React, { useState } from 'react';
import GenieContext from 'services/genie/context';
import axios from 'axios';
import { getAuthorizationHeader } from 'services/sessions/authorization_token';

const GenieProvider = ({ children }) => {
  const [askingGenie, setAskingGenie] = useState(false);

  const askGenie = async ({ file, text, workflowId, env, useOcr = false }) => {
    console.group('askGenie');
    console.log('file', file);
    console.log('text', text);
    console.log('workflowId', workflowId);
    console.log('env', env);
    console.groupEnd();
    setAskingGenie(true);
    const authHeader = {
      Authorization: getAuthorizationHeader(env),
    };
    let response;
    if (file) {
      const formData = new FormData();
      formData.append('file', file); // fields is your fields configuration
      formData.append('workflowId', workflowId);
      formData.append('useOcr', useOcr);
      formData.append('env', env);
      try {
        response = await axios.post(
          'http://localhost:9586/extract-fields',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              ...authHeader,
            },
          },
        );
        return response.data;
      } catch (error) {
        console.error('Error asking genie:', error);
        throw error;
      } finally {
        setAskingGenie(false);
      }
    } else {
      try {
        response = await axios.post(
          'http://localhost:9586/extract-fields',
          {
            text,
            workflowId,
            env,
          },
          {
            headers: {
              ...authHeader,
            },
          },
        );
        return response.data;
      } catch (error) {
        console.error('Error asking genie:', error);
        throw error;
      } finally {
        setAskingGenie(false);
      }
    }
  };

  const ocr = async ({ file, workflowId, env }) => {
    console.group('ocr');
    console.log('file', file);
    console.log('workflowId', workflowId);
    console.log('env', env);
    console.groupEnd();
    setAskingGenie(true);
    const authHeader = {
      Authorization: getAuthorizationHeader(env),
    };

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('workflowId', workflowId);
      formData.append('env', env);
      try {
        const response = await axios.post(
          'http://localhost:9586/ocr',
          formData,
          {
            headers: {
              ...authHeader,
            },
          },
        );
        return response.data;
      } catch (error) {
        console.error('Error asking genie:', error);
        throw error;
      } finally {
        setAskingGenie(false);
      }
    } else {
      setAskingGenie(false);
    }
  };

  return (
    <GenieContext.Provider value={{ askGenie, askingGenie, ocr }}>
      {children}
    </GenieContext.Provider>
  );
};

export default GenieProvider;
