import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Button from 'components/ui/button';
import { LOCALES } from 'constants';
import isNil from 'lodash/isNil';
import { useI18n } from 'services/i18n/hook';
import { useFile } from 'services/file/hook';
import { useWatson } from 'services/watson/hook';
import WarningButton from 'components/watson/warning_button';
import EnvironmentSelectField from 'components/environment/select_field';

const WatsonSelectorForm = () => {
  const { t } = useI18n();
  const { selectFile } = useFile();
  const [file, setFile] = useState(null);
  const {
    loading,
    setEnv,
    env,
    runWatson,
    checkTemplate,
    reset,
    setLocale,
    locale,
  } = useWatson();

  const getFile = async () => {
    const selectedFile = await selectFile((updatedFile) => {
      runWatson(updatedFile, true);
    });
    if (selectedFile) {
      setFile(selectedFile);
    }
    reset();
  };

  const generateTemplate = (locale = null) => {
    runWatson(file, true, locale);
  };

  const check = () => {
    checkTemplate(file);
  };

  return (
    <div className="p-2 bg-200">
      <InputGroup className="mb-2">
        <Button
          variant="secondary"
          size="sm"
          onClick={getFile}
          icon={['fa', 'file-word']}
        >
          {t('components.watson.selector.form.select_template')}
        </Button>
        <Form.Control
          size="sm"
          placeholder={t(
            'components.watson.selector.form.no_template_selected',
          )}
          value={file ? file.name : ''}
          disabled
          onChange={() => {}}
        />
        {file && (
          <Button
            variant="secondary"
            size="sm"
            onClick={check}
            icon={['fa', 'list-check']}
          >
            {t('components.watson.selector.form.check_template')}
          </Button>
        )}
      </InputGroup>
      <InputGroup>
        <Form.Select
          size="sm"
          placeholer={t('components.watson.selector.form.locale')}
          disabled={loading}
          value={locale}
          onChange={(ev) => {
            setLocale(ev.target.value);
            generateTemplate(ev.target.value);
          }}
        >
          {Object.keys(LOCALES).map((env) => (
            <option value={env} key={env}>
              {t(`global.locales.${env}`)}
            </option>
          ))}
        </Form.Select>
        <EnvironmentSelectField env={env} setEnv={setEnv} loading={loading} />
        <Button
          variant="primary"
          size="sm"
          onClick={() => generateTemplate(locale)}
          disabled={isNil(file)}
          icon={['fa', 'print']}
        >
          {t('components.watson.selector.form.generate')}
        </Button>
        <WarningButton />
      </InputGroup>
    </div>
  );
};

export default WatsonSelectorForm;
